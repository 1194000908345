// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/mts/icon-mts--dropdown-arrow.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu[data-v-1f41f9f4]{display:flex;align-items:stretch;min-height:52px}@media(max-width:991px){.menu[data-v-1f41f9f4]{display:none}}.menu__item[data-v-1f41f9f4]{display:flex;align-items:center;position:relative;cursor:pointer}.menu__item[data-v-1f41f9f4]:not(:first-child){margin-left:24px}.menu__item[data-v-1f41f9f4]:before{content:\"\";display:block;position:absolute;width:0;height:3px;left:0;bottom:0;border-radius:50px;background:#ff0032;transition:all .25s ease}.menu__item[data-v-1f41f9f4]:active:before,.menu__item[data-v-1f41f9f4]:focus:before,.menu__item[data-v-1f41f9f4]:hover:before{width:100%}.menu__item-dropdown[data-v-1f41f9f4],.menu__item-wrapper[data-v-1f41f9f4]{display:flex}.menu__item-dropdown_link[data-v-1f41f9f4]{display:flex;align-items:center;text-decoration:none;color:inherit;font-size:17px;line-height:24px;font-weight:400;transition:all .3s ease}.menu__item-dropdown_link[data-v-1f41f9f4]:active,.menu__item-dropdown_link[data-v-1f41f9f4]:focus,.menu__item-dropdown_link[data-v-1f41f9f4]:hover{color:#ff0032}.menu__item-dropdown_link-icon[data-v-1f41f9f4]{display:block;margin-right:12px}.menu__link[data-v-1f41f9f4]{color:inherit;text-decoration:none}.menu[data-v-1f41f9f4] .dropdown__content_list-item:not(:last-child){margin-bottom:20px}.menu[data-v-1f41f9f4] .dropdown__content_list:before{content:\"\";position:absolute;top:-8px;width:20px;height:8px;left:91px;background:#fff no-repeat 50%;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
