<template>
  <div class="social">
    <a v-for="link in links" :key="link" :href="link.href" class="social__links">
      <VIconMTS
        :name="link.icon"
        :width="icon.width"
        :height="icon.height"
        :fill="icon.color"
        class="social__links-icon" />
    </a>
  </div>
</template>
<script>
import VIconMTS from "~/components/Common/MTS/VIconMTS.vue";

export default {
  name: 'SocialLinksBlock',
  components: { VIconMTS },
  props: {
    icon: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      links: [
        {
          href: 'https://t.me/mts_link',
          icon: 'social/icon-mts--telegram'
        },
        {
          href: 'https://vk.com/mtslink',
          icon: 'social/icon-mts--vk'
        },
        {
          href: 'https://www.youtube.com/@mtslink',
          icon: 'social/icon-mts--youtube'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.social {
  &__links {
    display: inline-block;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
