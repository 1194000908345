<template>
  <header class="header-demo">
      <div class="header-demo-inner">
        <div class="logo">
          <a href="/" class="logo-link">
              <img class="logo__img" src="~/assets/img/main/logo--mts-icon-full.svg" alt="logo" width="68" height="26" />
          </a>
        </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderDemoMTS',
};
</script>

<style lang="scss" scoped>
.header-demo {
  position: absolute;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 100;
  min-width: 336px;


  &-inner {
    padding-left: 25px;
    padding-right: 25px;
  }

}

.logo {
  &-link {
    display: block;
  }
}
</style>
