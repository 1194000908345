export default {
  data() {
    return {
      industriesListLink: [
        // {
        //   title: 'Промышленность',
        //   href: '/task/industry/',
        //   icon: {
        //     name: 'icon-mts--delivery',
        //     alt: 'Промышленность',
        //   },
        // },
        {
          title: 'Здравоохранение',
          href: '/task/healthcare/',
          icon: {
            name: 'icon-mts--heartAdd',
            alt: 'Здравоохранение',
          },
        },
        {
          title: 'Образование',
          href: '/task/obuchenie_studentov/',
          icon: {
            name: 'icon-mts--education',
            alt: 'Образование',
          },
        },
        {
          title: 'Госсектор',
          href: '/task/govermental/',
          icon: {
            name: 'icon-mts--federal',
            alt: 'Госсектор',
          },
        },
        // {
        //   title: 'Retail и\u00A0FMCG',
        //   href: '/task/retail/',
        //   icon: {
        //     name: 'icon-mts--market',
        //     alt: 'Retail',
        //   },
        // },
        // {
        //   title: 'IT',
        //   href: '/task/it/',
        //   icon: {
        //     name: 'icon-mts--dataQualityCheck',
        //     alt: 'Retail',
        //   },
        // },
      ],
      productsListLink: [
        {
          title: 'Встречи',
          icon: {
            name: 'icon-mts--videoReview',
            alt: 'Встречи',
          },
          href: '/products/meetings/',
        },
        {
          title: 'Вебинары',
          icon: {
            name: 'icon-mts--calendar',
            alt: 'Вебинары',
          },
          href: '/products/webinar/',
        },
        {
          title: 'Курсы',
          icon: {
            name: 'icon-mts--audiobook',
            alt: 'Курсы',
          },
          href: '/products/westudy/',
        },
        {
          title: 'On-premise',
          icon: {
            name: 'icon-mts--dataQualityCheck',
            alt: 'On-premise',
          },
          href: '/products/on-premise/',
        },
        {
          title: 'Чаты',
          icon: {
            name: 'icon-mts--chat',
            alt: 'Чаты',
          },
          href: '/products/messenger/',
        },
        {
          title: 'Доcки',
          icon: {
            name: 'icon-mts--shape',
            alt: 'Доcки',
          },
          href: '/products/boards/',
        },
        {
          title: 'Comdi',
          icon: {
            name: 'icon-mts--hotspot',
            alt: 'Comdi',
          },
          href: '/products/comdi/',
        },
        {
          title: 'Rooms',
          icon: {
            name: 'icon-mts--callTracking',
            alt: 'Rooms',
          },

          href: 'https://mts-link.ru/products/rooms/',
        },
      ],
      toggleNameDropdown: 0,
      translateForContent: 0,
    }
  },
  methods: {
    openDropdown(number) {
      this.toggleNameDropdown = number
      this.translateForContent = event.target.offsetLeft - 73
    },
  },
}
